:root {
    --color-primary: #0073c5; //changed
    --color-primary-70: rgba(0, 115, 197, 0.44); //changed
    --color-secondary: #FE4E3E; //changed
    --color-secondary-disabled: #F1B2AE; //changed
    --color-text-default: #545463; //changed
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey:  #F2F2F6; //changed
    --color-light-grey--dark: #9C9CA3; //changed
    --color-dark-grey: #BEC2C6;
    --color-dark: #545463; //changed
    --color-mid-grey: #727279; //changed

    --color-alkaline-light: #D6E5F2;
    --color-alkaline-dark: #306BAA;
    --color-netural: #fff;
    --color-acidic-light: #F5BDB9;
    --color-acidic-dark: #CD5557;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-basica-skin: #D5AFB9;
    --color-basica-classic: #87AFC8;
    --color-basica-energy: #BE404C;
    --color-basica-sport: #88C5C6;
    --color-basica-immun: #EBA157;

    --product-color: #b6d9f0; //default color


    --color-primary-contrast: #035691;
    --color-secondary-contrast: #A82E2C;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #035691;
    --color-secondary-dark: #A82E2C;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #87afc8;
    --color-secondary-light: #FABEC5;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --color-primary-lightest: #E5F1F9; //changed
    --color-primary-lightest-30: #E5F1F930; //changed
    --color-primary-lightest-50: #E5F1F950; //changed
    --color-primary-light-30: rgba(137, 175, 200, 0.3); //changed


    --font-default-bold: "ff-meta-web-pro", sans-serif;
    --font-default: "ff-meta-web-pro", sans-serif;

    --font-weight-bold: 700;
    --font-weight-default: 500;

    --font-size-default: calc(20rem /16);
    --font-size-default--mobile: calc(16rem /16);

    --container-max-width: calc(1640rem/16);
    --container-md-max-width: calc(1080rem/16);
    --container-lg-max-width: calc(1355rem/16);
    --container-sm-max-width: calc(800rem/16);
    --container-padding: calc(30rem/16);
}
