@media screen and (max-width: 767px) {
    @each $key, $ratio in $aspect-ratios-xs {
        .ratio-#{$key}-xs {
            --bs-aspect-ratio: #{$ratio};
        }
    }
}
.ratio-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ratio-cover-centered{
    height: 100%;
    overflow: hidden;
}

.ratio-cover-centered > .ratio-item {
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (min-width: 768px) {
    .ratio.ratio-cover-centered--md{
        height: 100%;
        overflow: hidden;
    }

    .ratio.ratio-cover-centered--md .ratio-item {
        width: auto;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}