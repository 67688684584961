$primary: #0073c5;
$secondary: #FE4E3E;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
$light: #fff;
//$dark:          $gray-900 !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 1117px,
    xl: 1140px,
    xxl: 1680px
);

$aspect-ratios: (
    "1x1": 100%,
    "2x1": calc(1 / 2 * 100%),
    "3x1": calc(1 / 3 * 100%),
    "3x2": calc(2 / 3 * 100%),
    "4x3": calc(3 / 4 * 100%),
    "3x4": calc(4 / 3 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "66x61": calc(61 / 66 * 100%),
    "532x368": calc(368 / 532 * 100%),
    "10x7":  calc(7 / 10 * 100%),
    "portal-hero": calc(94 / 113 * 100%),
    "hero": calc(49 / 94 * 100%),
    "image-slide": calc(49 / 94 * 100%),
    "media-area": 56.25%,
    "content-slide-product": calc(41 / 38 * 100%),
    "product-teaser": calc(18 / 24 * 100%),
);

$aspect-ratios-xs: (
    "1x1": 100%,
    "3x2": calc(2 / 3 * 100%),
    "4x3": calc(3 / 4 * 100%),
    "3x4": calc(4 / 3 * 100%),
    "portal-hero": calc(412 / 375 * 100%),
    "hero": calc(185 / 375 * 100%),
    "image-slide": calc(185 / 375 * 100%),
    "content-slide-product": calc(22 / 26 * 100%)
);

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    40: calc(40rem/16),
    60: calc(60rem/16),
    6: $spacer * 5
);


$btn-padding-y: calc(9rem / 16);
$btn-padding-x: calc(20rem / 16);
$btn-font-family: var(--font-decorative-bold);
$btn-font-size: 1rem;
$btn-line-height: calc(22rem / 16);
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping
$btn-border-width: 0;
$btn-font-size: calc(18rem / 16);
$btn-border-radius: 0;
$btn-box-shadow:  0 20px 40px 0 rgba(29,29,27,0.1);


$grid-gutter-width: 2.5rem;

$input-padding-y:                       0.8125rem;
$input-padding-x:                       1.875rem;
$input-font-family:                     var(--font-family-default);
$input-font-size:                       1.25rem;
$input-line-height:                     1.6;
$input-border-color:                    transparent;
$input-border-radius:                   0;
$input-placeholder-color:               #9C9CA3;



$input-group-addon-padding-y:           1.125rem;
$input-group-addon-padding-x:           1.5rem;
//$input-group-addon-font-weight:         $input-font-weight;
$input-group-addon-color:               $primary;
$input-group-addon-bg:                  #f2f2f6;
//$input-group-addon-border-color:        $input-border-color;


//$modal-sm:                          300px;
//$modal-md:                          500px;
//$modal-lg:                          800px;
$modal-xl:                          1360px;

$table-border-color: transparent;
$table-cell-padding-y:        1rem;
$table-cell-padding-x:        1.25rem;
$table-striped-color:         #727279;
$table-striped-bg-factor:     .02;
$table-striped-bg:            rgba($table-striped-color, $table-striped-bg-factor);
$table-striped-order: even;
$table-color: #727279;